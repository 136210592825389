<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('OcorrenciasModelo')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary"
              v-if="loading">
            </v-progress-circular>

            <v-btn
              @click="novo"
              color="success"
              v-if="!loading && (perfil == 'ADMIN' || modelos.length == 0)">
              <v-icon>
                mdi-plus
              </v-icon>
              {{$i18n.t('Adicionar')}}
            </v-btn>

            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
                v-if="!loading">
              </v-text-field>
            </v-card-title>

            <v-data-table :mobile-breakpoint="0"
              v-if="!loading"
              dense
              :headers="headers"
              :items="modelos"
              item-key="id"
              :search="search"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-text': $i18n.t('RegistrosPorPagina'),
                'items-per-page-options': [10, 15, 20, 30, 50, 100],
                'pageText': '{0}-{1} de {2}'
              }">

              <template v-slot:item.id="{ item }">
                <div class="link" @click="edita(item)">
                  {{$i18n.t('Editar')}}
                </div>
              </template>

            </v-data-table>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  
  export default {
    data: () => ({
      loading: false,
      perfil: '',
      search: '',
      modelos: []
    }),
    computed: {
      headers() {
        let headers = [];

        headers.push({ text: this.$i18n.t('Transportador'), value: 'empresa.nome', sortable: true });

        headers.push(
          { text: '', value: 'id', sortable: false}
        );
        
        return headers;
      }
    },
    methods: {
      load() {
        this.loading = true;
        this.$http.get('/api/doc/ocorrencia/modelos')
            .then((req) => {
                this.modelos = req.data;
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      novo() {
        this.$router.push('/ocorrencias/modelo/add');
      },
      edita(modelo) {
        this.$router.push('/ocorrencias/modelo/' + modelo.id);
      }
    },
    mounted() {
      this.perfil = this.$auth.info().perfil;
      this.load();
    }
  }
</script>
<style>
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
  }
  .theme--dark.v-data-table tbody tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, .5);
  }
</style>